<template>
  <div id="containernew">
    <!--div id="container" class="svg-container"-->
    <svg :id="'rankskill' + id"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    id: {
      type: String,
      required: true,
      default: null,
    },
    width: {
      type: Number,
      default: 250,
    },
    height: {
      type: Number,
      default: 300,
    },
    data: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {},

  mounted() {
    this.plotChart();
  },

  methods: {
    plotChart() {
      var propHeight = this.height;
      if (this.data.length < 5) {
        propHeight = 80;
      } else if (this.data.length >= 5 && this.data.length < 10) {
        propHeight = 150;
      } else if (this.data.length >= 10 && this.data.length < 20) {
        propHeight = 250;
      } else if (this.data.length >= 20 && this.data.length < 30) {
        propHeight = 350;
      } else if (this.data.length >= 30 && this.data.length < 40) {
        propHeight = 400;
      } else if (this.data.length >= 40 && this.data.length < 60) {
        propHeight = 500;
      } else if (this.data.length >= 60 && this.data.length < 80) {
        propHeight = 600;
      } else if (this.data.length >= 80) {
        propHeight = 700;
      }
      const margin = { top: 10, right: 30, bottom: 10, left: 80 };
      const width = this.width - margin.left - margin.right;
      const height = propHeight - margin.top - margin.bottom;

      const x = d3
        .scaleLinear()
        .domain([
          0,
          Math.max(this.data[0].score, this.data[this.data.length - 1].score),
        ])
        .range([0, width]);
      const y = d3
        .scaleBand()
        .range([0, height])
        .domain(
          this.data.map(function (d) {
            return d.coach ? d.coach.id : d.player ? d.player.id : "";
          })
        )
        .padding(0.2);

      const svg = d3
        .select("#rankskill" + this.id)
        /*  .attr("preserveAspectRatio", "xMinYMin meet")*/
        .attr(
          "viewBox",
          `0 0 ${width + margin.left + margin.right} ${
            height + margin.top + margin.bottom
          }`
        );
      //   .classed("svg-content", true);

      this.chart = svg
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // add the x Axis
      /*     const chart = this.chart
        .append("g")
        .attr("transform", "translate(0," + height + ")");

      if (!this.hideX) {
        chart.call(d3.axisBottom(x));
      }

      chart
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("font-size", "0.65rem")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)");  */

      // add the y Axis
      /*   this.chart
        .append("g")
        .attr("class", "grid")
        .call(d3.axisLeft(y).tickSize(0));*/

      // append the rectangles for the bar chart

      this.chart
        .selectAll(".bar")
        .data(this.data)
        .enter()
        .append("rect")
        .attr("fill", function (d) {
          return d.color;
        })
        .attr("x", x(0))
        .attr("height", y.bandwidth())
        .attr("y", function (d) {
          return y(d.coach ? d.coach.id : d.player ? d.player.id : "");
        })
        .attr("width", 0)
        .transition()
        .duration(750)
        .delay(function (d, i) {
          return i * 150;
        })
        .attr("x", (d) => {
          return x(0);
        })
        .attr("width", (d) => {
          return x(d.score > 0 ? d.score : 0);
        });

      this.chart
        .selectAll(".value")
        .data(this.data)
        .enter()
        .append("text")
        .attr("fill", "#FFFFFF")
        .attr("font-size", "0.5rem")
        .attr("class", "value")
        .attr("y", function (d) {
          return (
            y(d.coach ? d.coach.id : d.player ? d.player.id : "") +
            y.bandwidth() / 2 +
            3
          );
        })
        .attr("x", function (d) {
          return x(d.score) + 3;
        })
        .text(function (d) {
          return d.score != "" &&
            !isNaN(d.score) &&
            Math.round(d.score) != d.score
            ? Math.round(d.score * 100) / 100
            : d.score;
        });

      this.chart
        .selectAll(".label")
        .data(this.data)
        .enter()
        .append("text")
        //  .attr("fill", "#FFFFFF")
        .attr("fill", function (d) {
          return d.colorLabel ? d.colorLabel : "#FFFFFF";
        })
        .attr("font-size", "0.5rem")
        .attr("cursor", "pointer")
        .attr("class", "label")
        .attr("y", function (d) {
          return (
            y(d.coach ? d.coach.id : d.player ? d.player.id : "") +
            y.bandwidth() / 2 +
            3
          );
        })
        .attr("x", function (d) {
          return x(0) - 55;
        })
        .text(function (d, i) {
          return (
            (d.rank ? d.rank : i) +
            1 +
            " - " +
            (d.coach
              ? d.coach.allenatore
                ? d.coach.allenatore.cognome
                : d.coach.name
              : d.player
              ? d.player.name
              : "")
          );
        })
        .on("click", function (d, i) {
          var link = i.coach
            ? "/scouting/view/coach/" + i.coach.id
            : i.player
            ? "/scouting/view/player/" + i.player.id
            : "";
          window.open(link);
        });

      this.chart
        .selectAll(".image")
        .data(this.data)
        .enter()
        .append("image")
        .attr("xlink:href", (d) =>
          d.coach
            ? d.coach.allenatore
              ? d.coach.allenatore.avatar_url
              : "/assets/images/agente.png"
            : d.player
            ? d.player.avatar
              ? d.player.avatar
              : "/assets/images/profile.png"
            : "/assets/images/profile.png"
        )
        .attr("width", "10")
        .attr("height", "10")
        .attr("class", "image")
        .attr("y", function (d) {
          return (
            y(d.coach ? d.coach.id : d.player ? d.player.id : "") +
            y.bandwidth() / 2 +
            3 -
            10
          );
        })
        .attr("x", function (d) {
          return x(0) - 70;
        });

      //      const lineMedia = this.media;

      /*    this.chart
        .append("line")
        .attr("x1", function () {
          return x(lineMedia);
        })
        .attr("x2", function () {
          return x(lineMedia);
        })
        .attr("y1", 0)
        .attr("y2", height)
        .attr("stroke-width", 1)
        .attr("stroke", "white")
        .attr("stroke-dasharray", "5,5");

      this.chart
        .append("text")
        .attr("fill", "#FFFFFF")
        .attr("font-size", "0.65rem")
        .attr("class", "barsEndlineText")
        .attr("text-anchor", "middle")
        .attr("x", function () {
          return x(lineMedia);
        })
        .attr("y", -2)
        .text(lineMedia); */
    },
  },
};
</script>
<style>
.tooltip {
  position: absolute;
  z-index: 999;
  width: 150px;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -mox-box-shadow: 4px 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rbga(0, 0, 0, 0.4);
  pointer-events: none;
}
.tooltip.hidden {
  opacity: 0;
}
.tooltip p {
  margin: 0;
  font-size: 10px;
  line-height: 20px;
}

.grid {
  font: 10px sans-serif;
}
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.grid path {
  stroke-width: 0;
}
</style>
